// Utils for interacting with YouTube videos
let videoUtils = {
    pauseVideos: function(container) {
        const iframeVideos = container.querySelectorAll('iframe');
        if (iframeVideos.length > 0) {
            iframeVideos.forEach((iframe) => {
                if (iframe.contentWindow) {
                     // Pause Youtube Videos
                     if (iframe.src.startsWith('https://www.youtube.com')) {
                         iframe.contentWindow.postMessage(JSON.stringify({ event: 'command', func: 'pauseVideo' }), 'https://www.youtube.com');
                     }
                }
            });
        }
    },
    checkAutoplayVideos: function() {
        let autoplayVideo = document.querySelector('.overlap-container video[autoplay]');
        if ((autoplayVideo) && (this.checkReducedMotion())) {
            // this function will only be invoked if query for prefers-reduced-motion is true, and there is a video in the
            // overlap container with the autoplay attribute present.
            let videoParent = autoplayVideo.parentNode;
            videoParent.querySelector('.video-controls').setAttribute('data-state', 'visible');
            videoParent.classList.remove('overlay');
            let playPause = videoParent.querySelector('.play-pause');
            playPause.setAttribute('data-state','play');
            // we have to remove the autoplay attribute, otherwise the browser will ignore the setting!
            autoplayVideo.removeAttribute('autoplay');
            autoplayVideo.pause();
            autoplayVideo.controls = false; // hide the default controls
            playPause.addEventListener('click', function(e) {
                if (autoplayVideo.paused || autoplayVideo.ended) {
                    autoplayVideo.play();
                    this.setAttribute('data-state','pause');
                } else {
                    autoplayVideo.pause();
                    this.setAttribute('data-state','play');
                }
            });
        } 
    },
    sandboxVideos: function(selector) {
        const iframeSandboxVideos = document.querySelectorAll(selector);
        if (iframeSandboxVideos.length > 0) {
            iframeSandboxVideos.forEach((iframe) => {
                if (!iframe.hasAttribute('sandbox')) {
                    iframe.setAttribute('sandbox','allow-scripts allow-same-origin allow-presentation allow-popups allow-popups-to-escape-sandbox');
                }
            });
        }
    },
    checkReducedMotion() {
        return window.matchMedia('(prefers-reduced-motion: reduce)').matches;
        // to test this without having to mess with OS settings, you can comment out the above line and return false or true instead
        // false will work as normal, true will assume reduced motion is active
        // return true;
    }
};