//add focus outlines
const KEYBOARD_FOCUS_ONLY = (e) => {
    document.addEventListener('keyup', function(e) {
        let video = document.querySelector('.page-movie');
        if (video) {
            if ((e || window.event).keyCode === 13) {
                video.paused ? video.play() : video.pause();
            }
        }
        if (e.keyCode === 9) {
            document.body.classList.add('focus-outlines');
        }
    });
    document.addEventListener('click', function(e) {
        document.body.classList.remove('focus-outlines');
    });
};