document.addEventListener('DOMContentLoaded', () => {
    //ACCORDION FOR DESKTOP
    const desktopAccComponents = document.querySelectorAll('.desktop-accordion-component');
    desktopAccComponents.forEach((accordion) => {
        const mainBackgroundImage = accordion.querySelector('img');
        const mainValue = mainBackgroundImage.src;
        const panelItem = accordion.querySelectorAll('.desktop-panel-item');
        const panelContent = accordion.querySelectorAll('.desktop-panel-content');
        const closePanel = accordion.querySelectorAll('.close-panel');
        if (closePanel) {
            closePanel.forEach(function(close) {
                close.addEventListener('click', function() {
                    accordion.classList.remove('active');
                    toggleCont();
                    mainBackgroundImage.src = mainValue;
                    this.parentNode.style.width = '0';
                });
            });
        }
        panelItem.forEach((panelItem) => {
            const childrenCount = accordion.childElementCount;
            let calculate = 1185 / childrenCount;
            let contentWidth = 1185 - ((childrenCount - 1) * 40 + calculate) + 'px';
            panelItem.style.width = calculate + 'px';
            const bgValue = panelItem.getAttribute('data-background');
            panelItem.onclick = function() {
                mainBackgroundImage.src = bgValue;
                let setClasses = !this.classList.contains('active');
                toggleCont();
                panelContent.forEach( (panel) => {
                    // if we are closing the panel pause any Videos playing in it
                    if ( panel.offsetWidth > 0  ) {
                        videoUtils.pauseVideos(panel);
                    }
                    panel.style.width = '0';
                });
                let check = !this.classList.contains('active');
                if (check) {
                    accordion.classList.toggle('active');
                }
                if (setClasses) {
                    this.classList.toggle('active');
                    accordion.classList.add('active');
                    this.nextElementSibling.classList.add('visible');
                    this.nextElementSibling.style.width = contentWidth;
                } else {
                    mainBackgroundImage.src = mainValue;
                    this.nextElementSibling.style.width = '0';
                }
            };
        });

        const setClass = (els, className, fnName) => {
            els.forEach((els) => {
                els.classList[fnName](className);
            });
        };
        const toggleCont = () => {
            setClass(panelItem, 'active', 'remove');
            setClass(panelContent, 'visible', 'remove');
        };
    });

    //ACCORDION FOR MOBILE VIEW
    const mobileAccComponents = document.querySelectorAll('.mobile-accordion-component');
    mobileAccComponents.forEach((accordion) => {
        const accordionLink = accordion.getElementsByClassName('mobile-accordion-heading');
        const accordionContent = accordion.querySelectorAll('.mobile-accordion-content');
        let singleMode = true;
        accordionContent.forEach((accContent) => {
            const setDataHeight = () => {
                accContent.setAttribute('data-height', realHeight + 'px');
                accContent.style.height = 0;
            };
            let realHeight = accContent.scrollHeight;
            setDataHeight();
        });
        for (let i = 0; i < accordionLink.length; i++) {
            accordionLink[i].onclick = function() {
                let openedAcc = this.getAttribute('href').replace('#', '');
                if (this.classList.contains('active')) {
                    this.classList.remove('active');
                    document.getElementById(openedAcc).style.height = 0;
                    document.getElementById(openedAcc).style.visibility = 'hidden';
                    let openPanelEl = document.getElementById(openedAcc);
                    videoUtils.pauseVideos(openPanelEl);
                    return false;
                }
                if (singleMode) {
                    for (let k = 0; k < accordionLink.length; k++) {
                        // if the panel is open then pause any videos playing in panel
                        if ( accordionLink[k].classList.contains('active')) {
                            let openAccID = accordionLink[k].getAttribute('href').replace('#', '');
                            let openPanelEl = document.getElementById(openAccID);
                            videoUtils.pauseVideos(openPanelEl);
                        }
                        accordionLink[k].classList.remove('active');
                    }
                    for (let j = 0; j < accordionContent.length; j++) {
                        accordionContent[j].style.height = 0;
                        accordionContent[j].style.visibility = 'hidden';
                    }
                }
                this.classList.add('active');
                document.getElementById(openedAcc).style.height = 'auto';
                document.getElementById(openedAcc).style.visibility = 'visible';
                return false;
            };
            //Default open
            if (accordionLink[i].classList.contains('active')) {
                let openedAcc = accordionLink[i].getAttribute('href').replace('#', '');
                document.getElementById(openedAcc).style.height = 'auto';
                document.getElementById(openedAcc).style.visibility = 'visible';
            } else {
                let openedAcc = accordionLink[i].getAttribute('href').replace('#', '');
                document.getElementById(openedAcc).style.visibility = 'hidden';
            }
        }
    });
});