// Generic lazy loading functions
let lazyLoad = {
    initBackgroundLazyLoader: function(element, imageUrl) {
        // check there is an image
        if (!imageUrl) {
            return;
        }

        // use intersection observer if available to lazy load background image
        if ('IntersectionObserver' in window) {
            let lazyBackgroundObserver = new IntersectionObserver(function (entries, observer) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {
                        lazyLoad.setBackgroundImage(entry.target, imageUrl);
                        lazyBackgroundObserver.unobserve(entry.target);
                    }
                });
            });

            lazyBackgroundObserver.observe(element);
        } else {
            // otherwise just load the image
            lazyLoad.setBackgroundImage(element, imageUrl);
        }
    },

    setBackgroundImage: function(element, imageUrl) {
        element.style.backgroundImage = `URL(${imageUrl})`;
    }

};