const MIXED_NEWS = document.querySelector('.mixed-news-component');

function createPaginationIcon( buttonClass, iconClass, tooltipText ) {
  let btn = document.createElement('button');
  btn.classList = buttonClass;
  let icon = document.createElement('span');
  icon.classList = iconClass;
  btn.appendChild(icon);
  let tooltip = document.createElement('span');
  tooltip.classList.add('visually-hidden');
  tooltip.innerText = tooltipText;
  btn.appendChild(tooltip);
  return btn;
}



if (MIXED_NEWS) {
    //loader and error message
    let loadCirc = document.createElement('div');
    loadCirc.classList = 'list-loader';
    let loadMsg = document.createElement('span');
    let loadWrap = document.createElement('div');
    loadWrap.classList = 'loader-wrapper';
    loadWrap.appendChild(loadCirc);
    loadWrap.appendChild(loadMsg);
    const NEWS_CONTENT_WRAPPER = document.createElement('div');
    NEWS_CONTENT_WRAPPER.classList = 'news-content-wrapper';
    const paginationComponent = document.createElement('div');
    paginationComponent.setAttribute('id', 'pagination');
    MIXED_NEWS.appendChild(NEWS_CONTENT_WRAPPER);
    MIXED_NEWS.appendChild(paginationComponent);
    let currentPage = 1;
    const SCROLL_TOP = () => {
        scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };
    let getSelectedMixedNews = async function (filterTag) {
        loadMsg.innerText = document.querySelector('.rest-message--success').value;
        loadMsg.classList = 'loader-title';
        MIXED_NEWS.appendChild(loadWrap);
        NEWS_CONTENT_WRAPPER.style.display = 'none';
        paginationComponent.style.display = 'none';
        let newsPerPage = document.querySelector('.newsPerPage').value;
        let response = await fetch(`${APIPrefix.get()}/search_api/genericnews?filterTag=${filterTag}&noOfItemsPerPage=${newsPerPage}`);
        if (!response.ok) {
            NEWS_CONTENT_WRAPPER.style.display = 'none';
            loadWrap.removeChild(loadCirc);
            loadMsg.innerText = document.querySelector('.rest-message--fail').value;
            loadMsg.classList = 'error-title';
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        let data = await response.json();
        MIXED_NEWS.removeChild(loadWrap);
        NEWS_CONTENT_WRAPPER.style.display = 'flex';
        paginationComponent.style.display = 'flex';
        return data;
    };

    let diplayFilteredList = function (items, wrapper, rows_per_page, page) {
        wrapper.innerHTML = '';
        page--;
        let start = rows_per_page * page;
        let end = start + rows_per_page;
        let paginatedItems = items.slice(start, end);
        for (let item of paginatedItems)  {
            let item_element = `
              <div class="mixed-news-wrapper">
                ${item.image.path ? `<a href = "${item.button.url}"><img src="${item.image.path}" alt="${item.image.alt}" title="${item.title}"/></a>` : ``}
                <p class="mixed-news-date"> ${item.date}</p>
                <h3 class="mixed-news-title">${item.title}</h3>
                <p class="mixed-news-desc">${item.shortDescription}</p>
                <a class="mixed-news-cta button" href="${item.button.url}" title="${item.button.tooltip}" >${item.button.name}</a>
              </div>
            `;
            wrapper.innerHTML += item_element;
        }
    };

  if (window.innerWidth >= 1024) {
    const BTN_FILTER_CONTENT_WRAP = document.querySelector('.btn-filters-wrapper');
    const ALL_BTN = BTN_FILTER_CONTENT_WRAP.firstElementChild;
    window.addEventListener('load', () => {
      ALL_BTN.click();
    });
    const FILTER_BTNS = document.querySelectorAll('.news-tag-btn');
    FILTER_BTNS.forEach((btn) => {
      btn.addEventListener('click', (e) => {
        e.preventDefault();
        e.target.parentElement.querySelectorAll('.active').forEach((e) => e.classList.remove('active'));
        e.target.classList.add('active');
        let dataTagAttr = btn.getAttribute('data-tag');

        getSelectedMixedNews(dataTagAttr).then((data) => {
          const FILTERED_DATA = data.data.news;
          let currentPage = 1;
          let rows = data.data.configuration.itemsPerPage; //
          const NEWS_CONFIGURATION = data.data.configuration;
          function setupMixedPagination(items, wrapper, rows_per_page) {
            wrapper.innerHTML = '';
            let pageCount = Math.ceil(items.length / rows_per_page);
            for (let i = 1; i < pageCount + 1; i++) {
              let fltbtn = FilteredPaginationButton(i, items, pageCount, NEWS_CONFIGURATION.tooltips);
              wrapper.appendChild(fltbtn);
            }

            let nextBtn = createPaginationIcon( 'page-next', 'icon-single-chevron', NEWS_CONFIGURATION.tooltips.nextPage );
            let prevBtn = createPaginationIcon( 'page-prev', 'icon-single-chevron-left', NEWS_CONFIGURATION.tooltips.prevPage );
            let firstBtn = createPaginationIcon( 'page-first', 'icon-double-chevron-left', NEWS_CONFIGURATION.tooltips.firstPage );
            let lastBtn = createPaginationIcon( 'page-last', 'icon-double-chevron', NEWS_CONFIGURATION.tooltips.lastPage );

            let theFirstChild = paginationComponent.firstChild;
            let lastChild = paginationComponent.lastElementChild;
            paginationComponent.insertBefore(prevBtn, theFirstChild);
            paginationComponent.insertBefore(firstBtn, prevBtn);

            function insertAfter(referenceNode, newNode) {
              referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
            }
            insertAfter(lastChild, nextBtn);
            insertAfter(nextBtn, lastBtn);

            if (currentPage == 1) {
              prevBtn.classList.add('disabled');
              prevBtn.setAttribute('tabindex', -1);
            } else {
              prevBtn.classList.remove('disabled');
            }
            if (currentPage == pageCount) {
              nextBtn.classList.add('disabled');
              nextBtn.setAttribute('tabindex', -1);
            } else {
              nextBtn.classList.remove('disabled');
            }
            if (currentPage == 1) {
              firstBtn.classList.add('disabled');
              firstBtn.setAttribute('tabindex', -1);
            } else {
              firstBtn.classList.remove('disabled');
            }
            if (currentPage == pageCount) {
              lastBtn.classList.add('disabled');
              lastBtn.setAttribute('tabindex', -1);
            } else {
              lastBtn.classList.remove('disabled');
            }
            prevBtn.addEventListener('click', () => {
              currentPage = currentPage - 1;
              diplayFilteredList(items, NEWS_CONTENT_WRAPPER, rows, currentPage);
              setupMixedPagination(FILTERED_DATA, paginationComponent, rows);
            });

            nextBtn.addEventListener('click', () => {
              currentPage = currentPage + 1;
              diplayFilteredList(items, NEWS_CONTENT_WRAPPER, rows, currentPage);
              setupMixedPagination(FILTERED_DATA, paginationComponent, rows);
            });
            firstBtn.addEventListener('click', () => {
              currentPage = 1;
              diplayFilteredList(items, NEWS_CONTENT_WRAPPER, rows, currentPage);
              setupMixedPagination(FILTERED_DATA, paginationComponent, rows);
            });
            lastBtn.addEventListener('click', () => {
              currentPage = pageCount;
              diplayFilteredList(items, NEWS_CONTENT_WRAPPER, rows, currentPage);
              setupMixedPagination(FILTERED_DATA, paginationComponent, rows);
            });
          }

          function FilteredPaginationButton(page, items, pageCount, tooltips) {
            let filtButton = document.createElement('button');
            filtButton.innerHTML = `<span class="visually-hidden">${tooltips.page}</span> ${page}`;
            filtButton.id = page;
            if (currentPage == page) filtButton.classList.add('active');
            filtButton.addEventListener('click', () => {
              currentPage = page;
              let currentFilteredBtn = document.querySelector('#pagination button.active');
              diplayFilteredList(items, NEWS_CONTENT_WRAPPER, rows, currentPage);
              let last = document.querySelector('.page-next');
              let pageLast = document.querySelector('.page-last');
              let first = document.querySelector('.page-first');
              let prev = document.querySelector('.page-prev');

              if (currentPage == pageCount) {
                last.classList.add('disabled');
                pageLast.classList.add('disabled');
                last.setAttribute('tabindex', -1);
                pageLast.setAttribute('tabindex', -1);
              } else {
                last.classList.remove('disabled');
                pageLast.classList.remove('disabled');
                last.setAttribute('tabindex', 0);
                pageLast.setAttribute('tabindex', 0);
              }
              if (currentPage == 1) {
                prev.classList.add('disabled');
                first.classList.add('disabled');
                prev.setAttribute('tabindex', -1);
                first.setAttribute('tabindex', -1);
              } else {
                prev.classList.remove('disabled');
                first.classList.remove('disabled');
                prev.setAttribute('tabindex', 0);
                first.setAttribute('tabindex', 0);
              }
              currentFilteredBtn.classList.remove('active');
              filtButton.classList.add('active');
            });
            return filtButton;
          }

          diplayFilteredList(FILTERED_DATA, NEWS_CONTENT_WRAPPER, rows, 1);
          if (FILTERED_DATA.length > rows) {
            setupMixedPagination(FILTERED_DATA, paginationComponent, rows);
            paginationComponent.style.visibility = 'visible';
          } else {
            paginationComponent.style.visibility = 'hidden';
          }

          if (FILTERED_DATA === undefined || FILTERED_DATA.length == 0) {
            let ERROR_MSG = document.createElement('span');
            ERROR_MSG.innerText = NEWS_CONFIGURATION.headerTemplateMessage;
            ERROR_MSG.setAttribute('class', 'no-items');
            NEWS_CONTENT_WRAPPER.style.display = 'none';
            MIXED_NEWS.appendChild(ERROR_MSG);
          }
        }).catch(e =>console.log(e));
      });
    });
  } else {
    const SELECT_FILTER = document.querySelector('#mixedFiltersMobile');
    window.addEventListener('load', () => {
      if ('createEvent' in document) {
        let evt = document.createEvent('HTMLEvents');
        evt.initEvent('change', false, true);
        SELECT_FILTER.dispatchEvent(evt);
      } else SELECT_FILTER.fireEvent('onchange');
    });
    SELECT_FILTER.addEventListener('change', () => {
      let value = SELECT_FILTER.options[SELECT_FILTER.selectedIndex].value;
      localStorage.setItem('selectedValue', value);

      getSelectedMixedNews(value)
        .then((data) => {
          const FILTERED_MOBILE_DATA = data.data.news;
          let currentPage = 1;
          let rows = data.data.configuration.itemsPerPage;
          const NEWS_CONFIGURATION = data.data.configuration;

          function setupMixedMobilePagination(items, wrapper, rows_per_page) {
            wrapper.innerHTML = '';
            let pageCount = Math.ceil(items.length / rows_per_page);

            let nextBtn = createPaginationIcon( 'page-next', 'icon-single-chevron', NEWS_CONFIGURATION.tooltips.nextPage );
            let prevBtn = createPaginationIcon( 'page-prev', 'icon-single-chevron-left', NEWS_CONFIGURATION.tooltips.prevPage );
            let firstBtn = createPaginationIcon( 'page-first', 'icon-double-chevron-left', NEWS_CONFIGURATION.tooltips.firstPage );
            let lastBtn = createPaginationIcon( 'page-last', 'icon-double-chevron', NEWS_CONFIGURATION.tooltips.lastPage );

            paginationComponent.appendChild(firstBtn);
            paginationComponent.appendChild(prevBtn);
            paginationComponent.appendChild(nextBtn);
            paginationComponent.appendChild(lastBtn);
            if (currentPage == 1) {
              prevBtn.classList.add('disabled');
            } else {
              prevBtn.classList.remove('disabled');
            }
            if (currentPage == pageCount) {
              nextBtn.classList.add('disabled');
            } else {
              nextBtn.classList.remove('disabled');
            }
            if (currentPage == 1) {
              firstBtn.classList.add('disabled');
            } else {
              firstBtn.classList.remove('disabled');
            }
            if (currentPage == pageCount) {
              lastBtn.classList.add('disabled');
            } else {
              lastBtn.classList.remove('disabled');
            }
            prevBtn.addEventListener('click', () => {
              SCROLL_TOP();
              currentPage = currentPage - 1;
              diplayFilteredList(items, NEWS_CONTENT_WRAPPER, rows, currentPage);
              setupMixedMobilePagination(FILTERED_MOBILE_DATA, paginationComponent, rows);
            });

            nextBtn.addEventListener('click', () => {
              SCROLL_TOP();
              currentPage = currentPage + 1;
              diplayFilteredList(items, NEWS_CONTENT_WRAPPER, rows, currentPage);
              setupMixedMobilePagination(FILTERED_MOBILE_DATA, paginationComponent, rows);
            });
            firstBtn.addEventListener('click', () => {
              SCROLL_TOP();
              currentPage = 1;
              diplayFilteredList(items, NEWS_CONTENT_WRAPPER, rows, currentPage);
              setupMixedMobilePagination(FILTERED_MOBILE_DATA, paginationComponent, rows);
            });
            lastBtn.addEventListener('click', () => {
              SCROLL_TOP();
              currentPage = pageCount;
              diplayFilteredList(items, NEWS_CONTENT_WRAPPER, rows, currentPage);
              setupMixedMobilePagination(FILTERED_MOBILE_DATA, paginationComponent, rows);
            });
          }
          diplayFilteredList(FILTERED_MOBILE_DATA, NEWS_CONTENT_WRAPPER, rows, 1);
          if (FILTERED_MOBILE_DATA.length > rows) {
            setupMixedMobilePagination(FILTERED_MOBILE_DATA, paginationComponent, rows);
            paginationComponent.style.visibility = 'visible';
          } else {
            paginationComponent.style.visibility = 'hidden';
          }
          if (FILTERED_MOBILE_DATA === undefined || FILTERED_MOBILE_DATA.length == 0) {
            let ERROR_MSG = document.createElement('span');
            ERROR_MSG.innerText = NEWS_CONFIGURATION.headerTemplateMessage;
            ERROR_MSG.setAttribute('class', 'no-items');
            NEWS_CONTENT_WRAPPER.style.display = 'none';
            MIXED_NEWS.appendChild(ERROR_MSG);
          }
        })
        .catch((e) => console.log(e));
    });
  }

}

window.addEventListener('load', () => {
  windowUtils.addOrientationListener(() => {
    location.reload();
  });
});