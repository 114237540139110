document.addEventListener('DOMContentLoaded', function(event) {
    multiLevelHover();
    KEYBOARD_FOCUS_ONLY();
    ADD_TAB_INDEX();

    //init timeline
    timeline(document.querySelectorAll('.timeline'), {
        mode: 'horizontal',
        visibleItems: 4,
        forceVerticalMode: 900
    });
});

// page error return to previous page
const RETURN_BTN = document.querySelector('.return-link');
if (RETURN_BTN) {
    RETURN_BTN.addEventListener('click', function(e) {
        e.preventDefault();
        history.go(-1);
    });
}