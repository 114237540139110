//populate calendar with events for microsites
let calendarEl = document.getElementById('micrositesCalendar');
if (calendarEl) {
    async function getEvents() {
        let response = await fetch(
            // `https://raw.githubusercontent.com/ashes27/developments.json/master/fulCal.json`
            `${APIPrefix.get()}/search_api/eventscalendar`
        );
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        let data = await response.json();
        return data;
    }
    getEvents()
        .then((data) => {
            let calendar = new FullCalendar.Calendar(calendarEl, {
                events: data.data.calendarEvents,
                eventClick: function(info) {
                    info.jsEvent.preventDefault(); // don't let the browser navigate
                    if (info.event.url) {
                        window.open(info.event.url);
                    }
                },

                headerToolbar: {
                    center: 'title',
                    right: '',
                    start: 'today prev,next',
                },
                initialView: 'listWeek',

                displayEventEnd: true,
                dayMaxEventRows: 3,
                height: 500,
                expandRows: true,
            });
            calendar.render();
        })
        .catch((e) => console.log(e));

}