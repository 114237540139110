//logic for gallery component
const sitecoreEditorMode = document.querySelector('.sc-editor-mode');
const GALLERY_COMPONENT = document.querySelector('.gallery-component');
if (GALLERY_COMPONENT) {
    if (!sitecoreEditorMode) {
        //counting images for select mobile gallery
        // select all options
        // select all select-content group, and loop through each group
        // find each groups' children count and append to each option by order
        const GAL_SEL = document.querySelector('.gallery-select');
        const options = [...GAL_SEL.querySelectorAll('option')];
        const selectContentGroups = [...GAL_SEL.querySelectorAll('.select-content')];
        selectContentGroups.forEach((group, idx) => {
            const imgCount = group.querySelectorAll('.gallery-item').length;
            const optionText = options[idx].textContent;
            options[idx].innerHTML = `${optionText} (${imgCount})`;
        });
    }
    const simpleLink = document.querySelectorAll('.simple-link');
    if (simpleLink) {
        window.addEventListener('load', () => {
            Array.from(document.getElementsByClassName('simple-link'), (btn) => {
                const count = document.querySelectorAll(
                    `div.tab-item-content[data-id="${btn.dataset.id}"] .gallery-item`
                ).length;
                btn.appendChild(document.createTextNode(` (${count})`));
            });
        });
    }
    const GALLERY_HEADER = document.querySelectorAll('.gallery-header');
    if (GALLERY_HEADER) {
        //on page load make first btn of gallery header selected
        GALLERY_HEADER.forEach((galHeader) => {
            const GALLERY_TAB_HEADER_LINKS = galHeader.firstElementChild;
            const GALLER_TAB_HEADER_FIRST_LINK = GALLERY_TAB_HEADER_LINKS.firstElementChild;
            //on page load first btn of gallery header is selected
            window.addEventListener('load', () => {
                GALLER_TAB_HEADER_FIRST_LINK.click();
            });
        });
        //on page load first btn of gallery content link is active and the content is visible
        const GALLERY_LINKS_WRAPPER = document.querySelectorAll('.gallery-links-wrapper');
        GALLERY_LINKS_WRAPPER.forEach((wrap) => {
            const FIRST_LINK_CONTENT = wrap.firstElementChild;
            window.addEventListener('load', () => {
                FIRST_LINK_CONTENT.click();
            });
        });
        //handle tab functionality for gallery header buttons
        let handleGalleryLinkClick = (e) => {
            Array.from(e.target.parentElement.parentElement.querySelectorAll('.selected'), (e) =>
                e.classList.remove('selected')
            ); // remove `selected` class from every elements which contains him.
            e.target.classList.add('selected');
            document
                .querySelector(`div.gallery-tab-header-content[data-tab*="${e.target.dataset.tab}"]`)
                .classList.add('selected');
        };
        Array.from(document.getElementsByClassName('gallery-tab-link'), (btn) =>
            btn.addEventListener('click', handleGalleryLinkClick, false)
        );
    }
    //logic for gallery dropdown on mobile view
    const GALLERY_SELECT = document.querySelectorAll('.gallery-select-component');
    if (GALLERY_SELECT) {
        window.addEventListener('load', () => {
            const SELECT_CONTENT_LIST = document.querySelectorAll('.select-content-list');
            SELECT_CONTENT_LIST.forEach((selectContentList) => {
                const SELECT_CONTENT_LIST_FIRST = selectContentList.firstElementChild;
                SELECT_CONTENT_LIST_FIRST.classList.add('visible');
            });
            const GALLERY_SELECT_WRAP = document.querySelectorAll('.gallery-select');
        });
        GALLERY_SELECT.forEach((galSelComp) => {
            let valueNew = () => {
                const SELECT_CONTENT = galSelComp.querySelectorAll('.select-content');
                const GALLERY_SELECT = galSelComp.querySelector('.gallery-select');
                SELECT_CONTENT.forEach((selectContent) => {
                    selectContent.classList.remove('visible');
                });
                SELECT_CONTENT[GALLERY_SELECT.selectedIndex].classList.add('visible');
            };

            Array.from(document.getElementsByClassName('gallery-select'), (select) =>
                select.addEventListener('change', valueNew, false)
            );
        });
    }
    //init lightbox plugin
    const MEDIA_CONTENT = document.querySelectorAll('.gallery-layout');
    if (MEDIA_CONTENT) {
        //center gallery link if it's only one element
        const GALLERY_LINK_WRAPPER = document.querySelectorAll('.gallery-links-wrapper');
        GALLERY_LINK_WRAPPER.forEach((wrapper) => {
            const GALLERY_LINK_WRAPPER_CHILDREN = wrapper.childElementCount;
            if (GALLERY_LINK_WRAPPER_CHILDREN < 10) {
                wrapper.style.justifyContent = 'center';
            }
        });
        MEDIA_CONTENT.forEach((media) => {
            const initLightboxEl = media.querySelector('.grid');
            lightGallery(initLightboxEl, {
                selector: '.gallery-item',
                mode: 'lg-slide',
                zoom: true,
                getCaptionFromTitleOrAlt: true,
                download: false,
                share: false,
                subHtmlSelectorRelative: true,
            });
        });
    }
}