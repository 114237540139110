const mainHeroSliderInit = function() {
    const MAIN_HERO_SLIDER = document.querySelectorAll('.image-carousel-component'); // container above slider
    MAIN_HERO_SLIDER.forEach((sliderWrapper) => {
        const SLIDER = sliderWrapper.querySelector('.main-slider-wrapper'); // container with slider
        const controlsContainer = sliderWrapper.querySelector('.controls');
        const SLIDER_WRAPPER_COUNT = SLIDER.childElementCount;
        if (SLIDER_WRAPPER_COUNT > 1) {
            controlsContainer.style.display = 'block';
            const heroSlider = tns({
                container: SLIDER,
                items: 1,
                slideBy: '1',
                preventScrollOnTouch: 'auto',
                autoplay: false,
                autoplayButtonOutput: false,
                autoplayResetOnVisibility: false,
                mouseDrag: true,
                nav: false,
                autoHeight: true,
                navAsThumbnails: true,
                controlsContainer: controlsContainer,
            });
            let refreshSliderInsideTab = (e) => {
                heroSlider.refresh();
            };
            Array.from(document.getElementsByClassName('tablinks'), (btn) =>
                btn.addEventListener('click', refreshSliderInsideTab, false)
            );
        }
    });
};
if (document.querySelectorAll('.image-carousel-component')) {
    mainHeroSliderInit();
}

const simpleSliderInit = function() {
    const SIMPLE_SLIDER_COMPONENT = document.querySelectorAll('.simple-slider-component');
    SIMPLE_SLIDER_COMPONENT.forEach(function(sliderWrapper) {
        let ITEMS_DISPLAYED_VALUE = sliderWrapper.getAttribute('data-items-to-be-displayed');
        let ITEMS_SLIDE_BY_VALUE = sliderWrapper.getAttribute('data-items-to-be-slideby');
        const slider = sliderWrapper.querySelector('.simple-slider-component > div'); // container with slider
        const controlsContainer = sliderWrapper.querySelector('.controls');
        const sliderWrapperCount = slider.childElementCount;
        let thumbSlider = tns({
            items: ITEMS_DISPLAYED_VALUE,
            slideBy: ITEMS_SLIDE_BY_VALUE,
            container: slider,
            autoplay: false,
            navContainer: true,
            preventScrollOnTouch: 'auto',
            touch: true,
            mouseDrag: true,
            swipeAngle: 30,
            nav: false,
            loop: false,
            navAsThumbnails: true,
            controlsContainer: controlsContainer,
            responsive: {
                320: {
                    items: 1,
                    slideBy: '1',
                    preventScrollOnTouch: 'auto',
                },
                900: {
                    items: 1,
                    slideBy: 1,
                },
                990: {
                    items: 1,
                    slideBy: 1,
                },
                1023: {
                    items: 1,
                    slideBy: 1,
                },
                1025: {
                    items: 1,
                    slideBy: 1,
                },
                1100: {
                    items: 1,
                    slideBy: 1,
                },
                1192: {
                    items: ITEMS_DISPLAYED_VALUE,
                    slideBy: ITEMS_SLIDE_BY_VALUE,
                },
            },
        });
    });
};
if (document.querySelectorAll('.simple-slider-component')) {
    simpleSliderInit();
}

const descriptionCalloutSliderInit = function() {
    const descriptionCalloutComp = document.querySelectorAll('.description-callout-component');
    descriptionCalloutComp.forEach(function(descWrapper) {
        const slider = descWrapper.querySelector('.description-callout-wrapper');
        const controlsContainer = descWrapper.querySelector('.controls');
        const thumbSlider = tns({
            container: slider,
            items: 1,
            slideBy: '1',
            autoplay: false,
            navContainer: true,
            preventScrollOnTouch: 'auto',
            mouseDrag: true,
            nav: false,
            navAsThumbnails: true,
            controlsContainer: controlsContainer,
            responsive: {
                320: {
                    items: 1,
                    slideBy: '1',
                    preventScrollOnTouch: 'auto',
                },
                1400: {
                    disable: true,
                    controls: false
                }
            },
        });
    });
};
if (document.querySelectorAll('.description-callout-component')) {
    descriptionCalloutSliderInit();
}

const gridSliderMobile = function() {
    const mainGridSlider = document.querySelectorAll('.columns-mobile-slider'); // container above slider
    mainGridSlider.forEach(function(sliderWrapper) {
        const slider = sliderWrapper.querySelector('.grid'); // container with slider
        const controlsContainer = sliderWrapper.querySelector('.controls');
        const sliderWrapperCount = slider.childElementCount;
        if (sliderWrapperCount > 1 && screen.width < 1025) {
            controlsContainer.style.display = 'block';
            const gridSlider = tns({
                container: slider,
                items: 1,
                slideBy: '1',
                preventScrollOnTouch: 'auto',
                autoplay: false,
                autoplayButtonOutput: false,
                autoplayResetOnVisibility: false,
                mouseDrag: true,
                loop: false,
                nav: false,
                navAsThumbnails: true,
                controlsContainer: controlsContainer,
            });
        }
    });
};
if (document.querySelectorAll('.grid')) {
    gridSliderMobile();

}