const sliderNavPrev = document.querySelectorAll('.prev');
const sliderNavNext = document.querySelectorAll('.next');
const TIMELINE_COMP = document.querySelector('.timeline-component');


const ADD_TAB_INDEX = function() {
    const CONTROLS = document.querySelectorAll('.controls');
    const ACTIVE_ITEM = document.querySelectorAll('.tns-slide-active > a');
    const SLIDER_WRAPPER = document.querySelector('.main-slider-wrapper');
    const ACCORDION_PANEL = document.querySelectorAll('.desktop-panel-item');
    const GALLERY_IMG = document.querySelectorAll('.gallery-item');
    const MENU_IMAGE_ITEM_WRAP = document.querySelectorAll('.menu--item');

    const ON_ENTER_KEY = (element) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            element.click();
        }
    };

    if (MENU_IMAGE_ITEM_WRAP) {
        MENU_IMAGE_ITEM_WRAP.forEach((menuItm) => {
            menuItm.addEventListener('keyup', function(event) {
                let linkTo = menuItm.querySelector('a');
                if (event.keyCode === 13) {
                    linkTo.click();
                }
            });
        });
    }
    if (GALLERY_IMG) {
        GALLERY_IMG.forEach(function(GALLERY_IMG) {
            GALLERY_IMG.tabIndex = 0;
            GALLERY_IMG.addEventListener('keyup', function(event) {
                if (event.keyCode === 13) {
                    GALLERY_IMG.click();
                }
            });
        });
    }
    if (TIMELINE_COMP) {
        const TIMELINE_ARROWS = document.querySelectorAll('.timeline-nav-button');
        TIMELINE_ARROWS.forEach((timeArrow) => {
            timeArrow.tabIndex = 0;
        });
    }
    if (ACTIVE_ITEM) {
        ACTIVE_ITEM.forEach(function(ACTIVE_ITEM) {
            ACTIVE_ITEM.tabIndex = 0;
        });
    }

    if (SLIDER_WRAPPER) {
        SLIDER_WRAPPER.tabIndex = -1;
    }
    if (CONTROLS) {
        CONTROLS.forEach(function(CONTROLS) {
            CONTROLS.tabIndex = -1;
        });
    }
    if (sliderNavPrev) {
        sliderNavPrev.forEach((sliderNavPrev) => {
            sliderNavPrev.setAttribute('tabindex', 0);
            sliderNavPrev.addEventListener('keyup', function(event) {
                ON_ENTER_KEY(sliderNavPrev);
            });
        });
    }
    if (sliderNavNext) {
        sliderNavNext.forEach((sliderNavNext) => {
            sliderNavNext.setAttribute('tabindex', 0);
            sliderNavNext.addEventListener('keyup', function(event) {
                ON_ENTER_KEY(sliderNavNext);
            });
        });
    }

    if (ACCORDION_PANEL) {
        ACCORDION_PANEL.forEach(function(ACCORDION_PANEL) {
            ACCORDION_PANEL.tabIndex = 0;
            ACCORDION_PANEL.addEventListener('keyup', function(event) {
                ON_ENTER_KEY(ACCORDION_PANEL);
            });
        });
    }
};