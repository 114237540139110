function getNodeindex(elm) {
    let c = elm.parentNode.children,
        i = 0;
    for (; i < c.length; i++)
        if (c[i] == elm) return i;
}

//Multi level nav hover
function multiLevelHover() {
    const firstLevelNavigation = document.querySelectorAll('#mainNav > li');
    const searchBar = document.querySelector('.navigation .search-bar');
    const opts = {
        interval: 100,
        handleFocus: true,
    };
    if (firstLevelNavigation) {
        firstLevelNavigation.forEach((firstLevelNavItem) => {
            const navBar = firstLevelNavItem.parentNode;
            hoverintent(
                firstLevelNavItem,
                function() {
                    if (navBar.querySelector('.over')) {
                        navBar.querySelector('.over').classList.remove('over');
                    }
                    if (!firstLevelNavItem.classList.contains('over')) {
                        firstLevelNavItem.classList.add('over');
                    }
                    if (searchBar) {
                        // if the search bar is open then blur search input field and close search bar
                        if( searchBar.parentNode.classList.contains('open') ) {
                            searchBar.parentNode.classList.remove('open');
                        }
                    }
                },
                function() {
                    if (firstLevelNavItem.classList.contains('over')) {
                        firstLevelNavItem.classList.remove('over');
                    }
                }
            ).options(opts);
        });
    }
}

//Navigation update
const SECOND_LEVEL_NAVIGATION = document.querySelectorAll('.menu-second-level--navigation > li');
if (SECOND_LEVEL_NAVIGATION) {
    SECOND_LEVEL_NAVIGATION.forEach((secondLevelNav) => {
        const thirdLevelNav = secondLevelNav.querySelector('.menu-third-level--navigation');
        const thirdLevelDev = secondLevelNav.closest('.menu-second-level--bordered');
        const closestElem = secondLevelNav.closest('.menu-second-level--wrap');
        if (thirdLevelNav !== null) {
            let opts = {
                interval: 300,
                handleFocus: true,
            };
            hoverintent(
                secondLevelNav,
                function() {
                    // Handler in
                    closestElem.parentNode.classList.add('show-third-level');
                    secondLevelNav.classList.add('open');
                },
                function() {
                    // Handler out
                    closestElem.parentNode.classList.remove('show-third-level');
                    secondLevelNav.classList.remove('open');
                }
            ).options(opts);
            if (getNodeindex(secondLevelNav) + 1 > 10) {
                thirdLevelNav.parentNode.classList.add('left-2');
            }
            if (getNodeindex(secondLevelNav) + 1 > 20) {
                thirdLevelNav.parentNode.classList.add('left-3');
                thirdLevelNav.parentNode.classList.remove('left-2');
            }
            if (getNodeindex(secondLevelNav) + 1 > 30) {
                thirdLevelNav.parentNode.classList.add('left-4');
                thirdLevelNav.parentNode.classList.remove('left-2');
                thirdLevelNav.parentNode.classList.remove('left-3');
            }
            if (thirdLevelDev) {
                if (getNodeindex(secondLevelNav) + 1 > 9) {
                    thirdLevelNav.parentNode.classList.add('left-2');
                }
                if (getNodeindex(secondLevelNav) + 1 > 18) {
                    thirdLevelNav.parentNode.classList.add('left-3');
                    thirdLevelNav.parentNode.classList.remove('left-2');
                }
            }
        }
    });
}
/// MAIN NAVIGATION DESKTOP TAB NAVIGATION START
// Get the nav by id
const MAIN_NAVIGATION = document.getElementById('mainNav');

function unClassy() {
    // Remove the focus class
    if (MAIN_NAVIGATION) {
        MAIN_NAVIGATION.classList.remove('hover');
        // Remove the focus class from all its descendents
        const MAIN_NAV_DESCENDENTS = MAIN_NAVIGATION.querySelectorAll('li');
        MAIN_NAV_DESCENDENTS.forEach((mainNavDesc) => {
            mainNavDesc.removeAttribute('class');
        });
    }
}
/* For any clicks, clear the focus class from the nav and all its descendants, essentially closing the menu when a user clicks/taps outside of it. */
document.documentElement.onclick = function() {
    unClassy();
};
/* Manipulate focus classes in navigation. */
function classy() {
    unClassy();
    // Add the focus class to items that have focus
    // Get the element that currently has focus
    let focusedElement = document.activeElement;
    // If that element is the primary nav, add the class
    if (MAIN_NAVIGATION) {
        if (focusedElement.id == MAIN_NAVIGATION.id) {
            // Add the focus class
            MAIN_NAVIGATION.classList.add('hover');
        }
        // If nav contains the focused element, add the class
        if (MAIN_NAVIGATION.contains(focusedElement)) {
            focusedElement.classList.add('hover');
            let el = focusedElement;
            while (el.parentNode) {
                el.classList.add('hover');
                el = el.parentNode;
            }
        }
    }
}
/* Delay the assigning of classes to give the :focus a chance to catch up. There has to be a better way for this. */
document.documentElement.addEventListener('keydown', function(event) {
    if (event.key == "Tab") {
        setTimeout(classy, 300);
    }
});

//First level navigation with left and right arrow key
if (MAIN_NAVIGATION) {
    MAIN_NAVIGATION.addEventListener('keyup', function(event) {
        let active = document.querySelector('#mainNav > .hover');
        if (active) {
            let prev = active.previousElementSibling;
            let next = active.nextElementSibling;
            if (event.key == "ArrowLeft") {
                if (prev !== null) {
                    unClassy();
                    active.classList.remove('hover');
                    prev.classList.add('hover');
                    prev.querySelector('.hover > a').focus();
                }
            }
            if (event.key == "ArrowRight") {
                if (next !== null) {
                    unClassy();
                    active.classList.remove('hover');
                    next.classList.add('hover');
                    next.querySelector('.hover > a').focus();
                }
            }
        }
    });
}