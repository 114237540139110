let windowUtils = {
    screenOrientation: window.screen.orientation || window,
    changeOrientationEvent: window.screen.orientation ? 'change' : 'orientationchange',
    
    addOrientationListener: function(callback) {
        this.screenOrientation.addEventListener(this.changeOrientationEvent, callback, {passive: true});
    },

    removeOrientationListener: function(callback) {
        this.screenOrientation.removeEventListener(this.changeOrientationEvent, callback, {passive: true});
    }
};