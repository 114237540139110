// logic for tab component
const TAB_COMPONENT = document.querySelectorAll('.tab-component');
if (TAB_COMPONENT) {
    TAB_COMPONENT.forEach((tabComp) => {
        const TAB_ITEM_LINK = tabComp.querySelector('.tab-item-link');
        const TAB_CONTENT_WRAPPER = tabComp.querySelector('.tab-content-wrapper');
        //make first tab and content visible on page load
        if (TAB_ITEM_LINK && TAB_CONTENT_WRAPPER) {
            window.addEventListener('load', () => {
                const FIRST_TAB = tabComp.querySelector('.tab-item-link').firstElementChild;
                const FIRST_CONTENT = tabComp.querySelector('.tab-content-wrapper').firstElementChild;
                FIRST_TAB.click();
                FIRST_CONTENT.click();
            });
        }

        //handle tab functionality on click for every tab item
        let handleClick = (e) => {
            Array.from(e.target.parentElement.parentElement.querySelectorAll('.active'), (e) => e.classList.remove('active')); // remove `active` class from every elements which contains him.
            e.target.classList.add('active');
            document.querySelector(`div.tab-item-content[data-id*="${e.target.dataset.id}"]`).classList.add('active');
        };
        Array.from(document.getElementsByClassName('tablinks'), (btn) => btn.addEventListener('click', handleClick, false));
    });
}