// get the Prefix for API calls
let APIPrefix = {
    APIUrl: '',
    initialised: false,
    init: function() {
        //removeIf(production)
        if ( window.location.host === 'localhost:3000') {
            this.APIUrl = 'http://localhost:3003';
        } else if (window.location.host === 'bs-local.com:3000') {
            this.APIUrl = 'http://bs-local.com:3003';
        }
        //endRemoveIf(production)
        this.initialised = true;
    },
    get: function() {
        if(!this.initialised) {
            this.init();
        }
        return this.APIUrl;
    }
};


function visuallyHiddenText(text) {
    return `<span class="visually-hidden">${text}</span>`;
}

let APIUtils = (function () {
    let API_LOGIN_REQUEST = '/api/signin';
    let initialised = false;
    let loadCirc = {};
    let loadMsg = {};
    let loadWrap = {};


    const init = function () {
        //removeIf(production)
        if ( window.location.host === 'localhost:3000') {
            API_LOGIN_REQUEST = 'sign-in.html';
        }
        //endRemoveIf(production)

        // Loading message and icon to display when getting data from API
        loadCirc = document.createElement('div');
        loadCirc.classList = 'list-loader';
        loadMsg = document.createElement('span');
        loadWrap = document.createElement('div');
        loadWrap.classList = 'loader-wrapper';
        loadWrap.appendChild(loadCirc);
        loadWrap.appendChild(loadMsg);

        initialised = true;
    };

    const redirectUserToSigninPage = function() {
        if (!initialised) {
            init();
        }

        window.location.pathname = API_LOGIN_REQUEST;
    };

    // get data without populating a component
    const getData = async function (URL) {
        if (!initialised) {
            init();
        }

        let response = await fetch(URL);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        return await response.json();
    };

    const getComponentData = async function (component, URL, successMessage, errorMessage) {
        if (!initialised) {
            init();
        }

        loadMsg.innerText = successMessage;
        loadMsg.classList = 'loader-title';
        component.appendChild(loadWrap);
        let response = await fetch(URL);
        if (!response.ok) {
            loadMsg.innerText = errorMessage;
            loadMsg.classList = 'error-title';
            component.removeChild(loadWrap);
            component.appendChild(loadMsg);
            throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        component.removeChild(loadWrap);
        return await response.json();
    };

return {
    init: init,
    getData: getData,
    getComponentData: getComponentData,
    redirectUserToSigninPage : redirectUserToSigninPage
};

})();