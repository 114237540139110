//get color value and use for theme
const PRIMARY_THEME_COLOR = document.querySelector('.primary-theme');
const SECONDARY_THEME_COLOR = document.querySelector('.secondary-theme');
const FONT_THEME = document.querySelector('.font-theme');
const HTML = document.querySelector('html');

const hextToHSL = (H) => {
    // Convert hex to RGB first
    let r = 0,
        g = 0,
        b = 0;
    if (H.length == 4) {
        r = '0x' + H[1] + H[1];
        g = '0x' + H[2] + H[2];
        b = '0x' + H[3] + H[3];
    } else if (H.length == 7) {
        r = '0x' + H[1] + H[2];
        g = '0x' + H[3] + H[4];
        b = '0x' + H[5] + H[6];
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    let cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;

    if (delta == 0) h = 0;
    else if (cmax == r) h = ((g - b) / delta) % 6;
    else if (cmax == g) h = (b - r) / delta + 2;
    else h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0) h += 360;

    l = (cmax + cmin) / 2;
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    HTML.style.setProperty('--hue', h);
    HTML.style.setProperty('--sat', s + '%');
    HTML.style.setProperty('--light', l + '%');
};

const hextToHSLSec = (H) => {
    // Convert hex to RGB first
    let r = 0,
        g = 0,
        b = 0;
    if (H.length == 4) {
        r = '0x' + H[1] + H[1];
        g = '0x' + H[2] + H[2];
        b = '0x' + H[3] + H[3];
    } else if (H.length == 7) {
        r = '0x' + H[1] + H[2];
        g = '0x' + H[3] + H[4];
        b = '0x' + H[5] + H[6];
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    let cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;

    if (delta == 0) h = 0;
    else if (cmax == r) h = ((g - b) / delta) % 6;
    else if (cmax == g) h = (b - r) / delta + 2;
    else h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0) h += 360;

    l = (cmax + cmin) / 2;
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    HTML.style.setProperty('--sec-hue', h);
    HTML.style.setProperty('--sec-sat', s + '%');
    HTML.style.setProperty('--sec-light', l + '%');
};

hextToHSL(PRIMARY_THEME_COLOR.value);
hextToHSLSec(SECONDARY_THEME_COLOR.value);
//primary color theme for all elements
PRIMARY_THEME_COLOR.value ?
    HTML.style.setProperty('--primary-theme-color', PRIMARY_THEME_COLOR.value) :
    HTML.style.setProperty('--primary-theme-color', '#7e7e7e');

//secondary color theme JUST for secondary buttons and h2,h3,h4,h5,h6
SECONDARY_THEME_COLOR.value ?
    HTML.style.setProperty('--secondary-theme-color', SECONDARY_THEME_COLOR.value) :
    HTML.style.setProperty('--secondary-theme-color', '#7e7e7e');

//CDN fonts usage
FONT_THEME.value ?
    HTML.style.setProperty('--font-family-theme', FONT_THEME.value) :
    HTML.style.setProperty('--font-family-theme', 'Arial');