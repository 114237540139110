class HighlightsBannerComponent {
    constructor(highlightsBannerComponent) {
        if (!(highlightsBannerComponent instanceof HTMLElement)) {
            throw new TypeError("The element must be an HTML element.");
        }
        
        this.highlightsBannerComponent = highlightsBannerComponent;
        this.backgroundImage = '';
        
    }

    init() {
        //  check if we have a background image to load
        if (this.highlightsBannerComponent.hasAttribute("data-background-image")) {
            this.backgroundImage = this.highlightsBannerComponent.dataset.backgroundImage;
            lazyLoad.initBackgroundLazyLoader(this.highlightsBannerComponent, this.backgroundImage);
        }
    }
}

// Check for highlights banner components when the document has loaded and initialise if we have any 
document.addEventListener("DOMContentLoaded", function () {
    const highlightComponent = document.querySelectorAll('.highlights-banner-component');
    if (highlightComponent) {
        highlightComponent.forEach(function(component) {
            let highComponent = new HighlightsBannerComponent(component);
            highComponent.init();
        });
    }
});